import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WbWvjOBD+nl8xVyikUAUnTbJb9wrlDu4P3MGxHMshW+NYW1kykhwnPfLfD0u24ygvTXf3U5J5fWbmmVFeXnGbaVqggX+n1etmWWwe4L8RQKZV4b4AqJKm3G5jiJ5GALsRgFWhaupVu9HLccD55YAAVlNpMqWL2H8V1OKXMZnd3oFJqcBxNHlc3r2T/FyYqAsyvbuAceFiHof+LoSnSv0JGJdhH08Hm0bR7XGzziW+kO/Tdfmia5MNgO1Gky4LdT6lMtxyJWPI+AZZY8WlQdtOIKHp60qrSjKSKqF0DGuqx4TQLOMSyUBdKEaFN3K53giXDDeBQyv11s6OSl5Qj2C/CNNFVBjIlK6pZubpAPak8SBSSXQFDPwbWWBbqIQLb/hOKYJuURNv38MbxEquaRfjphR0G0MmcOOqE3wlCbdYmBhSlBZ1I/5WGcuzLUmVtCjtUHVd34bA/mHUUpJVQhCTakT5fGN1hTdfPWLKGJerGCL4hRel0pZKexjgAx1tbTPK8M9mcf5SZeizvz5+immV8JQk+MZRj6PJfHYP0T1Ek8Xne/Brt+ZYE8dX11siaYH7OJ8PWEIyLkTTBoxP86NFaARn+Juytl2iEwCXEE1mDwaQGnwfxuNVMOI4jKIEG/el3J3Dsri6WT8I4fEshE9BO65veepCEtL9jmJ4OhBMQ8HMC2rObN4xvfd2ZebIV7kNdb4FBZfktH7m9XTT61Mq0uYArnMg8DArN71Fm7w3qIHALGoNErUhhr+5xUmUZqhJotxGZ0raRoXBhjp5Qg3e9VZ1i2EeueMgGrMO13SyfBpuZ5MZZvPS5dhfGY2CWr7Gj91itUYt6JaUVOLwJruqcspUHfiWqmx8WmVr66rWlPHKxDCdeWiqsk0d+9Nw/vpc4odr+BFJ3JyOmNJLB1Pr/Yez7g0D7NEBW1lwyGlilKisa7FVZQyztlTtozaT+a7D7K/pRb7kZHlMl+VJuswXvly94pIk7rB1UxnkzFzOWmlGEo30NQb3QRqJ27icWySmpCnGUGoktaalm+sadSZU7QSHXoPwKxf+6JU7es4aBUHJLjyBLfOJa/m87XFbnpPRyqpGtmrw+CEMkUxcx/9QyqL+m9v895wLplEOHz0f6ISvxjVq0w6owcq4xrRdOVWTVn++NmOpPnxFcxdsWEC3MsHMOj4NWHt7MIHtvvbB4z0PiuCnRxFWkypRFXK/qK5rzzcZFQZvvh79u+HNeAiuUVoT7viha7jeoa8vYQD428cAt4PvGP5SIOMU/IkBKhmM3SmAX59h2dDHv2tBQQH7hrTck6QbzEM7r91o9z8e4tYBng0AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mxd';
export var confirmModalContainer = '_1ukx6mxi';
export var confirmModalContent = '_1ukx6mxh';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mxc';
export var modalContentWrapper = '_1ukx6mxb';
export var modalDescription = '_1ukx6mxf';
export var modalFooter = '_1ukx6mxg';
export var modalHeader = '_1ukx6mxe';
export var modalOverlay = '_1ukx6mxa';
export var promptModalContent = '_1ukx6mxj';
export var widthVar = 'var(--_1ukx6mx0)';